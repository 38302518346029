import React, { Component, useState, Fragment } from "react";
import PDF from "./pdf/PDF";
import PDFexport from "./pdf/PDFexport";
import ItemOption from "./parts/ItemOption";
import itemImg from './assets/img/10.jpg';
import settings from '../settings.json';
import './common.css';
import {functionFields} from './MyFunction';
import mikan from 'mikanjs';
import {Helmet} from 'react-helmet';
//import settings from './settings.json';

  export default class Item extends Component{
    constructor(){
      super();

      var todayDate = new Date();
      var todayDateString = todayDate.getFullYear() + '年' + (todayDate.getMonth()+1) + '月' + todayDate.getDate() + '日';

      this.state={
        title:'',
        butsugs:[],
        gusokus:[],
        honzon:[],
        takatuki:[],
        orin:[],
        service:[],
        other:[],
        syobun:false,
        db_data:{},
        spec_obj:{},
        text_obj:{},
        imgBaseUrl: settings.IMG_BASE_URL_BUTSUDAN,
        fileName:'まるいちのお仏壇セット内容_'+todayDateString,
        type:'',
        description:'',
        price:''
      }
    }

    componentDidMount () {
      var url = settings.GET_OBUTSUDAN_API_URL;
      var type = functionFields.getParameterByName('type');
      var db_id = functionFields.getParameterByName('db_id');

      this.setState({type:type});

      const getDBData = async () => {
        const res = await fetch(
          url + '?type='+type+'&sort_type=' + db_id
        );
        const data = await res.json();
        var responseObj = JSON.parse(data.data)[0];
        this.setState({db_data:responseObj});
        console.dir( responseObj);

        var spec_obj = JSON.parse(responseObj['spec_obj_string']);
        this.setState({ spec_obj:spec_obj});
        var textString = responseObj['text_obj_string'].replace(/\n/g, "<br>").replace(/\r/g, "<br>").replace(/\t/g, "<br>");
        var text_obj = JSON.parse(textString);
        this.setState({ text_obj:text_obj});

        this.setState({title:responseObj['name']});
        if(type==='butsudan_single'){
          this.setState({description:"お仏壇名:"+responseObj['name']+"/"+settings.DESCRIPTION});
        }else if(type==='butsugu_single'){
          this.setState({description:"お仏具名:"+responseObj['name']+"/"+settings.DESCRIPTION});
        }

        //console.dir(spec_obj);
        //console.dir(text_obj);

        if (Number(responseObj['db_id'])>=106 && Number(responseObj['db_id'])<=110) {
          let add_elm = '<div className=""><div>価格(税込)</div><div>99,800円</div></div>';
          var add_parent_elm = document.getElementsByTagName('ul')[0];
          var li = document.createElement('li');
          li.innerHTML = add_elm;
          li.childNodes[0].className = 'item-detail-grid';
          add_parent_elm.appendChild(li);
        } 

      };
  
      getDBData();


    if (navigator.userAgent.match(/iPhone|Android/)) {

    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
      

    }

    }


    onChangeOption(key, value){
      //console.log(key);
      //console.dir(value);
      if(key==='gusoku'){
        this.setState({gusokus:value});
      }else if(key==='honzon'){
        this.setState({honzon:value});
      }else if(key==='takatuki'){
        this.setState({takatuki:value});
      }else if(key==='orin'){
        this.setState({orin:value});
      }else if(key==='service'){
        this.setState({service:value});
      }
    }

    onChangeOtherOption(value){
      //console.dir(value);
      var dataArray = this.state.other;
      if( dataArray.indexOf(value) === -1){
        dataArray.push(value);
        this.setState({other:dataArray});
      }
    }
    

    render(){

      //console.log(this.state.type);

      return (
        <>
          <Helmet>
            <title>（株）まるいちの仏壇 お仏壇名:{this.state.title}/{settings.TITLE}</title>
            <meta
              name="description"
              content={this.state.description}
            />
          </Helmet>
        
          <div className="Item">
            <p className="pankuzu">
              <a href={settings.DEV+"/"}>トップ</a> ＞ <a href="/item-list">商品一覧</a> ＞ {this.state.title}
            </p>


            <h2 className="page-h2">{this.state.db_data['name']}</h2>
            <div className="item-detail-area">
              {this.state.text_obj['img_1'] && 
                <div className="item-box ">
                  <img src={this.state.imgBaseUrl+this.state.text_obj['img_1']} alt={"京都 仏壇 仏具 "+this.state.db_data['name']} />
                  <h3>{this.state.text_obj['mini_title_1']}</h3>
                  <p dangerouslySetInnerHTML={{ __html: this.state.text_obj['text_1'] }} />
                </div>
              }
              {this.state.text_obj['img_2'] && 
                <div className="item-box page-item-margin">
                  <img src={this.state.imgBaseUrl+this.state.text_obj['img_2']} alt={"京都 仏壇 仏具 "+this.state.db_data['name']} />
                  <h3>{this.state.text_obj['mini_title_2']}</h3>
                  <p dangerouslySetInnerHTML={{ __html: this.state.text_obj['text_2'] }} />
                </div>
              }
              {this.state.text_obj['img_3'] && 
                <div className="item-box page-item-margin">
                  <img src={this.state.imgBaseUrl+this.state.text_obj['img_3']} alt={"京都 仏壇 仏具 "+this.state.db_data['name']} />
                  <h3>{this.state.text_obj['mini_title_3']}</h3>
                  <p dangerouslySetInnerHTML={{ __html: this.state.text_obj['text_3'] }} />
                </div> 
              }
            </div>
            
            {/* テキストボックス
            <div className="item-status-box">
              <div>展示中</div>
              <div>即納可</div>
              <div>3年保証</div>
            </div>
            */}
            {/*
            <h2 className="title_underline">仏具</h2>
            <div className="option-area">
              <ItemOption 
                data={this.state}
                type='gusoku'
                changeOption = {this.onChangeOption.bind(this)}
                changeOtherOption = {this.onChangeOtherOption.bind(this)}//type==serviceの時のみ
              />
              <ItemOption 
                data={this.state}
                type='honzon'
                changeOption = {this.onChangeOption.bind(this)}
                changeOtherOption = {this.onChangeOtherOption.bind(this)}
              />
              <ItemOption 
                data={this.state}
                type='takatuki'
                changeOption = {this.onChangeOption.bind(this)}
                changeOtherOption = {this.onChangeOtherOption.bind(this)}
              />
              <ItemOption 
                data={this.state}
                type='orin'
                changeOption = {this.onChangeOption.bind(this)}
                changeOtherOption = {this.onChangeOtherOption.bind(this)}
              />
              <ItemOption 
                data={this.state}
                type='service'
                changeOption = {this.onChangeOption.bind(this)}
                changeOtherOption = {this.onChangeOtherOption.bind(this)}
              />
            </div>

            <div className="hidden-area">
              <PDF
                data={this.state}
              />
            </div>
            <PDFexport
              downloadFileName={this.state.fileName} 
              rootElementId="pdf" 
              text="PDFに保存"
              data = {this.props}
            />
            */}

            {/*
            <div className="item-closing">
              <button>お店で見る（Web来店予約）</button>
            </div>
            */}

            <div className="item-detail">
              <h3 className="item-detail-title">{this.state.db_data['name']}の詳細情報</h3>
              <ul>
                <li>
                  <div className="item-detail-grid">
                    <div>商品名</div>
                    <div>{this.state.db_data['name']}</div>
                  </div>
                </li>
                {/*
                <li>
                  <div className="item-detail-grid">
                    <div>定価</div>
                    <div>{ Number(this.state.db_data['price']).toLocaleString() }円(税込)</div>
                  </div>
                </li>
                */}
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>大きさ</div>
                    <div>高さ{this.state.spec_obj['H']}×幅{this.state.spec_obj['W']}×奥行{this.state.spec_obj['D']}(cm)</div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? '' : 'display-none' }>
                  <div className="item-detail-grid">
                    <div>種類</div>
                    <div>{this.state.spec_obj['classification']}</div>
                  </div>
                </li>
                <li >
                  <div className="item-detail-grid">
                    <div>カラー</div>
                    <div>{this.state.spec_obj['color']}</div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>機能</div>
                    <div>
                      {(() => {
                        if (this.state.spec_obj['function_1']) {
                          return <>{this.state.spec_obj['function_1']}</>
                        }
                      })()}
                      {(() => {
                        if (this.state.spec_obj['function_2']) {
                          return <>、{this.state.spec_obj['function_2']}</>
                        }
                      })()}
                      {(() => {
                        if (this.state.spec_obj['function_3']) {
                          return <>、{this.state.spec_obj['function_3']}</>
                        }
                      })()}

                    </div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>原産国</div>
                    <div>{this.state.spec_obj['madein']}</div>
                  </div>
                </li>

                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>台輪</div>
                    <div>{this.state.spec_obj['dairin']}</div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>戸板</div>
                    <div>{this.state.spec_obj['toita']}</div>
                  </div>
                </li>
                <li>
                  <div className="item-detail-grid">
                    <div>{this.state.type==='butsugu_single' ? '素材' : '主芯材' }</div>
                    <div>{this.state.spec_obj['material']}</div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>表面仕上</div>
                    <div>{this.state.spec_obj['hyoumen']}</div>
                  </div>
                </li>
                <li className={this.state.type==='butsugu_single' ? 'display-none' : '' }>
                  <div className="item-detail-grid">
                    <div>重量(kg)</div>
                    <div>{this.state.spec_obj['weight']}</div>
                  </div>
                </li>

              </ul>
            </div>
                    


          </div>
        </>
      );
    }
}
