import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import Header from './../parts/Header';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
import {Helmet} from 'react-helmet';
import settings from '../../settings.json';
//import bootstrap from './assets/bootstrap.css';

function ButsudanOwakare() {
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');

  useEffect(() => {

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

  setSeoTitle("お仏壇処分について/"+settings.TITLE);
  setSeoDescri("お仏壇処分についてご説明するページ/"+settings.DESCRIPTION);

  }, []);



  return (
  <>
    <Helmet>
      {/*<title>{seoTitle}</title>*/}
      <title>仏壇処分、買い替え｜上置仏壇専門店　京都【株式会社 まるいち】</title>
      <meta
        name="description"
        content="京都【株式会社 まるいち】では、お買い替えの際は古い仏壇の引取をいたします。引取り仏壇のサイズによってお見積りをさせていただきます。洋風でおしゃれな小さい仏壇に買い替えてみてはいかがでしょうか。標準仏具を付属しお求めやすいセット価格にて販売しております。完全予約制・同一時間１組限定。"
      />
    </Helmet>

    <Header />

    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ 仏壇処分</p>
      
      <h2 className="page-h2 feature-h2">仏壇処分</h2>
      <div className="feature-text mt-0">
        <ul className="mt-0">
            <li className="mb-05">「引っ越しに際して、お仏壇を<span>買い</span><span>替えたい</span>」</li>
            <li>「仏壇を継承する事になったが、<span>大きい</span><span>ので</span><span>買い替えたい</span>」</li>
        </ul>
        <p className="t-left mx-minus-02">など様々な理由により仏壇処分を検討している方に、方法や費用、注意点について解説いたします。</p>
      </div>
      <img src="images/features/top-syobun-1.jpg" alt={'京都の小型仏壇店まるいちの特徴:'+'仏壇処分'} className="feature-img" />
      <div className="feature-text">
        <div>
            <h2 className="feature-h2 t-center">仏壇の処分方法・費用について</h2>
            <h3 className="feature-h3 mb-0 t-center">１．菩提寺に依頼する</h3>
            <p>仏壇処分だけでなく、幅広く仏事に関する相談ができますので、お世話になっている菩提寺へまずご相談をお勧めします。</p>
            <p>近年では環境や防災の観点からお焚き上げ、仏壇処分を実施できない地域も多く、お断りされてしまうケースもございます。</p>
            <p>費用に関しては、お布施という形になるため明確な決まりはございませんので、事前に確認いただく必要があります。</p>
            <h3 className="feature-h3 mb-0 mt-3 t-center">２．リサイクル業者に依頼する</h3>
            <p>リサイクル業者、不用品回収業者へ依頼することも可能です。</p>
            <p>回収する仏壇は、閉眼供養（魂抜き）がお済の仏壇に限られる場合がほとんどですので、事前に菩提寺へご連絡の上、閉眼供養を済ませておく必要があります。</p>
            <p>費用に関しても企業により様々な価格設定があるためご確認が必要です。</p>
            <h3 className="feature-h3 mb-0 mt-3 t-center">３．自治体に依頼する</h3>
            <p>各自治体で定められた廃棄物として処分する（粗大ごみ）ことも可能です。</p>
            <p>各自治体の指定場所へ持ち込む必要があるため、労力が必要になります。</p>
            <p>費用に関しては比較的安価にてできますが、各自治体ごとに定められていますので、自治体のwebサイトや役所にご確認ください。</p>
            <h3 className="feature-h3 mb-0 mt-3 t-center">４．仏壇店に依頼する</h3>
            <p>手順等が明確になっている場合がほとんどですので、スムーズに手続きを行えます。</p>
            <p>費用に関しては（20,000円～100,000円）ほどが相場である場合がほとんどですが、仏壇の大きさや搬出難所等により変動がある場合もございます。　</p>
            <p>※新規仏壇購入の場合、処分費用がお安くなるところもあります。</p>

            <h2 className="feature-h2 t-center mt-3">まるいちの仏壇引取サービス</h2>
            <p className="p-center">弊社でも仏壇引取のご依頼を承っております。</p>
            <p className="p-center t-red">※仏壇引取のみのご依頼は承っておりません。（お買い換えに際しての仏壇処分のみ）</p>
            
            <h3 className="feature-h3 mb-0 pb-05 t-center">仏壇引取料金</h3>
            <div className="price-box-3 mx-minus-02">
                <div>
                    <p>上置きサイズ</p>
                    <img src="images/childPage/図1.jpg" />
                    <p>￥20,000</p>
                    <p className="t-small">作業人員：１名</p>
                    <p className="t-small">仏壇本体+仏具</p>
                    <p className="t-small">※段ボール１箱</p>
                </div>
                <div className="p-relative">
                    <p>台付サイズ</p>
                    <img src="images/childPage/図2.jpg" />
                    <span className="img-size-label">幅～60cmまで</span>
                    <p>￥40,000</p>
                    <p className="t-small">作業人員：2名</p>
                    <p className="t-small">仏壇本体+仏具</p>
                    <p className="t-small">※段ボール２箱</p>
                </div>
                <div className="p-relative">
                    <p>大型サイズ</p>
                    <img src="images/childPage/図3.jpg" />
                    <span className="img-size-label">幅60～90cmまで</span>
                    <p>￥60,000</p>
                    <p className="t-small">作業人員：2〜3名</p>
                    <p className="t-small">仏壇本体+仏具</p>
                    <p className="t-small">※段ボール２箱</p>
                </div>
            </div>
            <p className="mt-2">上記以外のサイズ別途見積もり致します。ただし、状況によっては追加費用が発生する場合や処分を承れない場合もございます。</p>

            <h2 className="feature-h2 mt-3 t-center">ご依頼の際は下記項目を事前に<span className="kaigyou-no">ご確認</span>・<span className="ご確認">ご報告</span>お願いします。</h2>
            <ul className="pl-1">
                <li>引取する仏壇の寸法（高さ×幅×奥行）</li>
                <li>形状（２段のもの、家具の上に置くものなど）</li>
                <li>引取をする仏具の有無</li>
                <li>引取仏壇の写真の撮影をお願いします</li>
                <li>設置場所の詳細（設置階数、搬出する扉の寸法など）</li>
            </ul>

            <h2 className="feature-h2 t-center mt-3">引取の際の注意点</h2>
            <h3 className="feature-h3 mb-0 t-center">１.閉眼供養・魂抜き</h3>
            <p>引取に際しては必ず菩提寺に依頼し、閉眼供養・魂抜きを行ってください。</p>
            <p>※閉眼供養・魂抜きがお済でない仏壇は引取できません。</p>
            <p>菩提寺がない方には、ご寺院様のご紹介も可能ですので、ご相談ください。</p>

            <h3 className="feature-h3 mb-0 mt-3 t-center">2.宗派による違い</h3>
            <p>ご宗派により、仏壇の処分に違いがある場合がございます。</p>
            <p>又、同じご宗派でも住職の方針により違いがある場合もありますので、菩提寺へご相談されるのがよろしいかと思われます。</p>

            <h3 className="feature-h3 mb-0 mt-3 t-center">3.仏壇の引き出し等のご確認</h3>
            <p>仏壇は、引き出しなど多くの収納スペースがあります。</p>
            <p>まれに仏具だけでなく、貴重品（印鑑や通帳など）が入っている場合があります。</p>
            <p>普段開けたりしない、家族がしまっていたりして気づかないこともあるので、仏壇引取前には確認が必要です。</p>

            <p className="t-red">一度引取した仏壇の返却はできません。</p>
            <p className="t-red">弊社では引取時に引取依頼書にご署名、押印を頂いております。</p>
        </div>

      </div>
      
    </div>
  </>
  );
}

export default ButsudanOwakare;
